.banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0;
}

.card-b {
  position: relative;
  width: 100%; /* Adjust width to leave space for tooth22 */
  height: 300px;
  
  padding: 20px;
 
  background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


.button-left {
  font-weight: bold;
  background-color: white;
  border: 1px solid rgba(175, 230, 255, 0.5);
  border-radius: 15px;
 
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: black;
  cursor: pointer;

  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 20px 40px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
  
  box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
              0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
              inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
              inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  
}

.button-left:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}



.button-right {
 
  background-color: #082b2a;
  border: 1px solid rgba(175, 230, 255, 0.5);
  border-radius: 15px;

  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 20px 40px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
  
  box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
              0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
              inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
              inset -4px -4px 8px rgba(19, 95, 216, 0.35);
 
}


.button-right:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);



}
.card-b span {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  padding: 40px;

  
  

}

.buttons-container {
  z-index:  2;
  display: flex;
  gap: 10px;
  margin-top: 20px }



.container-b{
  display: flex;
  justify-content:space-between;
  gap: 68rem;
  position: absolute;
  z-index: 1;
}
  
  .pic1{
    width: 260px;
    rotate: 325deg; /* Adjust width as needed */
    height: auto;
    
    
   
  }
  
  .pic2 {
   
   
    width: 200px; /* Adjust width as needed */
   height: auto;
    
  }
  
  .button-left,
  .button-right, .or-circle
 {
    margin-top: 20px;
    
  }
  .or-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: black;
    color: white;
    
    font-weight: bold;
    
  }