@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.services {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
    padding-top: 140px;
}

.main-text > span {
    display: flex;
    font-size: 50px;
    font-weight: bold;
    justify-content: center;
    
    color: #082b2a;
    font-family: 'Roboto', sans-serif;
   
}

.subtext > span {
    color: #19887f;
    align-items: center;
    text-align: center;
    display: block;
    font-size: 25px;
    padding-right:100px ;
    padding-left: 100px;
    font-family: 'Roboto', sans-serif;

}

.cards {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.card {
    width: 100%; /* Make sure the card takes full available width */
    max-width: 300px; /* Control max width for consistency */
    height: 450px; /* Fixed height for all cards */
    padding: 20px; /* Adjust padding for better content fitting */
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
    box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
                0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
                inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
                inset -4px -4px 8px rgba(19, 95, 216, 0.35);
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden; /* Hide overflowing content */
}

.card > img {
    width: 80px; /* Adjust the size of the image */
    height: auto;
    position: absolute;
    top: 20px; /* Align the image to the top of the container */
    left: 37%; /* Center the image horizontally */
    transform: translateX(-50%); /* Center the image horizontally */
}

.card > div > span {
    font-size: 23px; /* Bigger heading */
    font-weight: bold;
    margin-bottom: 18px;
    display: block;
    font-family: 'Roboto', sans-serif; /* Updated font family */
    margin-top: 100px;
    padding-top: 10px;
}

.card > ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    flex-grow: 1; /* Allow ul to take remaining space */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the list items vertically */
    overflow: hidden; /* Hide overflowing content */
}

.card > ul > li {
    font-size: 18px; /* Good font size */
    font-family: 'Open Sans', sans-serif; /* Good font */
    margin-bottom: 15px; /* Space between items */
    color: #ffffff; /* Text color matching the vibe */
    position: relative; /* Positioning for the icon */
    padding-left: 30px;
    text-align: left;
    font-family: 'Roboto', sans-serif; /* Space for the icon */
}

.card > ul > li::before {
    content: '\f5c9'; /* FontAwesome tooth icon unicode */
    font-family: 'Font Awesome 5 Free'; /* FontAwesome font family */
    font-weight: 900; /* FontAwesome solid weight */
    position: absolute;
    left: 0; /* Align icon to the left */
    top: 0; /* Align icon to the top */
    color: #ffffff; /* Icon color */
    font-size: 18px; /* Icon size */
    
}

.services-blur{
    
    width: 20rem;
    height: 30rem;
    right: 0px;
    opacity: 0.8;
}
.services-blur-l{
    width: 20rem;
    height: 30rem;
    left: 0px;
    opacity: 0.8;
}
