.Test{
    display: flex;
    gap: 1rem;
    padding: 2rem ;
    padding-top: 140px;
    

   
}

.left-t{
   display: flex;
   flex: 1 1;
   gap: 3rem;
   flex-direction: column;
   
 

}
.left-t>:nth-child(1){
  text-align: left;
    color:#19887f;
    font-weight: bold;
    font-size: x-large;
    font-family: 'Roboto', sans-serif;
}
.left-t>:nth-child(2){
    font-weight: bold;
    font-size: 50px;
    color: #082b2a;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.review{
    font-size: 18px;
    
    letter-spacing: 0.5px;
    
    font-family: 'Roboto', sans-serif;
    
    
    text-align: left;

    line-height: 40px;
    color: #082b2a;
}

.right-t{
    flex:1;
    position: relative;
   
}

.mainimg{
    position:absolute;
    object-fit: cover;
    width: 17rem;
    height: 20rem;
    right: 8rem;
    top: 2rem;

}


.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid #36b5a6;
    background-color: transparent;
    right: 9rem;
    top:0.9rem;
    
}

.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    top: 4rem;
    background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);;
    right: 7rem;
}

.arrow{
    display: flex;
    gap: 30px;
    position:absolute;
    bottom: 10px;
    left: 180px;
    cursor: pointer;
}

.status{

    color: #082b2a;
    font-size: 16px;
  font-style: italic;
  
  font-family: 'Roboto', sans-serif;
}
.test-blur{
    width: 20rem;
    height: 30rem;
    left: 0px;
    opacity: 0.8;
    
}

.name{

    color:#082b2a;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

